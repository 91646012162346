import React from "react";

import { withStyles } from "@material-ui/core/styles";
import ThermalMap from "./../components/ThermalMap";

const styles = {
  root: {},
  container: {
    display: "flex",
    justifyContent: "center"
  }
};

const Thermal = props => <ThermalMap />;

export default withStyles(styles)(Thermal);
