import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

//MUI
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import { HISTORY_SAGA } from "../actions";

import { withStyles } from "@material-ui/core/styles";

import { ResponsiveContainer, XAxis, YAxis, Line, LineChart } from "recharts";

class History extends Component {
  constructor(props) {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    const { authUser, getHistory, match, reroute } = this.props;
    let data;
    if (!authUser) {
      reroute("/");
    } else {
      let start = new Date().getTime();
      data = getHistory(
        match.params.hashID,
        Number((start - 1000 * 60 * 60 * 24 * 7).toFixed(0)),
        start
      );
    }
    this.setState({ authUser, data: data ? data : [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    // Return progress if loading
    if (!data.length) {
      return <LinearProgress color="secondary" style={{ marginTop: "3.5em" }} />;
    }

    return (
      <Grid container className={classNames(classes.container)}>
        <ResponsiveContainer width="95%" height={500}>
          <LineChart data={this.state.data}>
            <XAxis
              dataKey="Timestamp"
              domain={["auto", "auto"]}
              name="Time"
              tickFormatter={unixTime => moment(unixTime).format("MMMM, Do")}
              type="number"
            />
            <YAxis dataKey="Value" name="Value" />
            <Line dataKey={"Value"} dot={false} line={{ stroke: "#BCE8F4" }} name="Values" />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  data: state.history.data,
  router: state.router
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getHistory: (hashID, start, end) =>
    dispatch({
      type: HISTORY_SAGA,
      hashID,
      payload: { start, end }
    }),
  reroute: route => dispatch(push(route)),
  rerouteWithProps: (route, props) => dispatch(push(route, props))
});

const styles = theme => ({
  container: {
    padding: "1em"
  }
});

History.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(History);
