import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

//Auth
import { doLogOut } from "./../firebase/auth";
import * as ACTIONS from "../actions";

//Router
import * as ROUTE from "../routes";
import { push } from "react-router-redux";

//MUI
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Icons
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PersonIcon from "@material-ui/icons/Person";
import TapaIcon from "../static/Icons/tapaIcon.png";

//Colors
import { lightBlue } from "@material-ui/core/colors";

class Navigation extends Component {
  state = {
    anchorEl: null,
    itemSelected: null
  };

  render() {
    const { classes, authUser, isPolling, location, reroute } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    if (location && location.pathname === "/suitescreen") {
      return null;
    }
    const logOutHandler = () => {
      this.handleUserMenuClose();
      reroute(ROUTE.LANDING);
      this.props.resetApp();
      doLogOut();
    };

    return (
      <div className={classes.root}>
        {/* AppBar Logic */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            {/* Brand */}
            <img src={TapaIcon} alt={"logo"} height={20} />
            <Typography
              color="inherit"
              className={classes.typography}
              onClick={() => reroute(ROUTE.LANDING)}>
              TAPA
            </Typography>

            {(authUser && location.pathname === "/calendar") ||
            location.pathname === "/dashboard" ||
            location.pathname === "/health" ||
            location.pathname === "/lights" ||
            location.pathname === "/setpoints" ||
            location.pathname === "/temperature" ? (
              <IconButton
                className={classNames({
                  [classes.isPolling]: isPolling
                })}
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handlePolling}
                color="inherit">
                <AutorenewIcon />
              </IconButton>
            ) : null}

            {authUser && location.pathname !== "/" ? (
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleUserMenuOpen}
                color="inherit">
                <PersonIcon />
              </IconButton>
            ) : null}

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleUserMenuClose}>
              {/* ./Navigation/UserMenu */}
              <div style={{ fontVariant: "small-caps", outline: "none" }}>
                <MenuItem onClick={() => this.handleRoute(ROUTE.PROFILE)}>profile</MenuItem>
                <MenuItem onClick={() => this.handleRoute(ROUTE.DASHBOARD)}>dashboard</MenuItem>
                <MenuItem onClick={() => logOutHandler()}>logout</MenuItem>
              </div>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  // SignInDialog Handlers
  handleRoute = route => {
    this.props.reroute(route);
    this.setState({ anchorEl: null });
  };

  handlePolling = () => {
    const {
      startPolling,
      startAlarmsPolling,
      stopPolling,
      stopAlarmsPolling,
      isPolling
    } = this.props;

    const {
      location: { pathname }
    } = this.props;

    if (pathname === "/health") {
      if (isPolling) {
        stopAlarmsPolling();
      } else {
        startAlarmsPolling();
      }
    } else {
      if (isPolling) {
        stopPolling();
      } else {
        startPolling();
      }
    }
  };

  //UserMenu Handlers
  handleUserMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = item => {
    const { itemSelected } = this.state;
    this.setState({ itemSelected: itemSelected === item ? null : item });
  };
}

//Styles
const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3.5em"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "4em"
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main
  },
  isPolling: {
    color: lightBlue[200],
    animation: `polling 10s ${
      theme.transitions.easing.easeInOut
    } 200ms infinite, infinite-spinning 10s ${theme.transitions.easing.easeInOut} 200ms infinite`
  },
  "@keyframes polling": {
    "0%": {
      color: lightBlue[200]
    },
    "50%": {
      color: lightBlue[600]
    },
    "100%": {
      color: lightBlue[200]
    }
  },
  "@keyframes infinite-spinning": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(1440deg)"
    }
  },
  typography: {
    flex: 1,
    paddingLeft: "1em",
    cursor: "pointer"
  }
});

//Connect
const mapStateToProps = state => ({
  authUser: state.session.authUser,
  isPolling: state.data.isPolling,
  location: state.router.location
});

const mapDispatchToProps = dispatch => ({
  reroute: target => dispatch(push(target)),
  resetApp: () =>
    dispatch({
      type: ACTIONS.RESET_APP
    }),
  startPolling: () => dispatch({ type: ACTIONS.START_POLLING }),
  startAlarmsPolling: () => dispatch({ type: ACTIONS.START_ALARMS_POLLING }),
  stopPolling: () => dispatch({ type: ACTIONS.STOP_POLLING }),
  stopAlarmsPolling: () => dispatch({ type: ACTIONS.STOP_ALARMS_POLLING })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation));
