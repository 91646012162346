import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { DATA_SAGA } from "../actions";
import { watchUser } from "../firebase/db";

//MUI
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import { withStyles } from "@material-ui/core/styles";

//Components
import DashboardHeader from "../components/Dashboard/DashboardHeader";
import DataSelectorCard from "../components/Dashboard/DataSelectorCard";
import Pulse from "../static/animations/pulse.gif";

class Dashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      authUser: props.authUser,
      data: props.data,
      organizedData: props.data,
      selectedBuilding: ""
    };

    this.selectBuilding = this.selectBuilding.bind(this);
  }

  componentDidMount() {
    const { authUser, api, role, reroute, getData } = this.props;
    if (!authUser || role !== "admin") reroute("/");
    this.setState({ authUser });
    authUser && getData(api, role);
  }

  componentDidUpdate(prevProps) {
    const {
      authUser,
      api,
      data,
      organizedData,
      dispatch,
      role,
      getData
    } = this.props;

    if (prevProps.authUser !== authUser) {
      authUser && watchUser({ dispatch, uid: authUser.uid });
      authUser && getData(api, role);
    } else if (
      prevProps.data !== data ||
      prevProps.organizedData !== organizedData
    ) {
      this.setState({ data });
      this.setState({ organizedData });
    }
  }

  render() {
    const { classes, fetchingData, reroute } = this.props;
    const { data, organizedData } = this.state;

    // Return progress if loading
    if (fetchingData) {
      return (
        <LinearProgress color="secondary" style={{ marginTop: "3.5em" }} />
      );
    }

    return (
      <Grid container className={classNames(classes.container)}>
        <Grid item xs={12} style={{ marginBottom: "2em" }}>
          <DashboardHeader data={data} organizedData={organizedData} />
        </Grid>
        <Grid item xs={12}>
          <DataSelectorCard
            buttonText="temperature"
            buttonRoute="/temperature"
            reroute={reroute}
            cardMedia="http://districtcenterdc.com/images/front_images/front1b.jpg"
          />
          <DataSelectorCard
            buttonText="lights"
            buttonRoute="/lights"
            reroute={reroute}
            cardMedia="http://www.oneparking.com/wp-content/uploads/2015/12/1900-k-street-washington.jpg"
          />
          <DataSelectorCard
            buttonText="calendar"
            buttonRoute="/calendar"
            reroute={reroute}
            cardMedia="https://images1.loopnet.com/i2/7eeGgNq8OIHdm4W1LNkNxbn6By8w-7LIf63q4z76FKQ/112/image.jpg"
          />
          <DataSelectorCard
            buttonText="health"
            buttonRoute="/health"
            reroute={reroute}
            cardMedia="https://www.smithgroup.com/sites/default/files/styles/slideshow_wide_1x/public/2018-04/555_12th_Street_Repositioning_3.jpg"
            icon={<img src={Pulse} alt="pulse" height={20} />}
          />
        </Grid>
      </Grid>
    );
  }

  selectBuilding = building => {
    const { selectedBuilding } = this.state;
    this.setState({
      selectedBuilding: selectedBuilding === building ? "" : building
    });
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  data: state.data.data,
  organizedData: state.data.organizedData,
  fetchingData: state.data.fetchingData,
  role: state.user.role,
  scheme: state.app.scheme
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getData: (api, role) =>
    dispatch({
      type: DATA_SAGA,
      api,
      role
    }),
  reroute: route => dispatch(push(route)),
  rerouteWithProps: (route, props) => dispatch(push(route, props))
});

const styles = theme => ({
  container: {
    padding: "1em"
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1em",
    fontVariant: "all-small-caps",
    letterSpacing: ".5em",
    textAlign: "center",
    userSelect: "none",
    fontWeight: "200"
  },
  textContainer: {
    lineHeight: 1.75,
    padding: "0 1em"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fab: {
    color: theme.palette.background.default
  }
});

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  reroute: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard);
