import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";

//MUI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

//Components
import { PieChart, Pie } from "recharts";

//Icons
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FlashOffIcon from "@material-ui/icons/FlashOff";

//Colors
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

//Api
import {
  renderActiveShape,
  renderCustomizedLabel,
  getPoints,
  getDataPointNumber,
  getDataChips,
  generateChartData
} from "./chartApi";
import { isEmpty } from "../../util/ob";

class DashboardHeader extends Component {
  state = {
    activeIndex: 0,
    buildingFlip: true,
    devicesFlip: true,
    statusFlip: true
  };

  render() {
    const { classes, data, organizedData, width, reroute } = this.props;
    const { buildingFlip, devicesFlip, statusFlip } = this.state;

    /* 
      NEW PATH PARSER
    */

    const calculateAveTemp = data => {
      let sum = 0;
      let count = 0;

      data &&
        data.temperature.forEach(point => {
          if (point.DisplayName === "SpaceTemp") {
            if (point.Value < 500) {
              sum += Number(point.Value);
              count += 1;
            }
          }
        });

      return Number(sum / count).toFixed(1);
    };

    const aveTemp = calculateAveTemp(organizedData);

    let chartData = generateChartData(organizedData.alarms);
    let dataChips = getDataChips(data);
    let dataPoints = getDataPointNumber(data);
    let points = getPoints(organizedData.alarms);
    let emptySet = isEmpty(data);

    let count = 0;
    organizedData.alarms.forEach(alarm => {
      count += !alarm.acknowledged && alarm.state === "ALARM" ? 1 : 0;
    });

    return (
      <Grid container>
        {/* BUILDINGS */}
        <Grid item xs={12} sm={4} className={classNames(classes.header)}>
          <Card className={classes.card}>
            <Slide
              direction="right"
              in={buildingFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Ave. Temp
                </Typography>
                <Typography variant="h5" component="h2" className={classes.dataPoint}>
                  {aveTemp}°
                </Typography>
                <Typography className={classes.status} color="textSecondary">
                  fahrenheit
                </Typography>
              </CardContent>
            </Slide>
            <Slide
              direction="left"
              in={!buildingFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Polling Floors
                </Typography>
                <List className={classNames(classes.list, classes.buildingList)}>
                  <ListItem
                    classes={{ container: classes.listItemContainer }}
                    className={classes.listItem}>
                    <ListItemText primary={"Floor 2"} />
                    <ListItemSecondaryAction style={{ display: "flex", flexBasis: "10%" }}>
                      <FlashOnIcon style={{ color: green[200] }} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </CardContent>
            </Slide>
            <CardActions className={classes.actionButton}>
              <Button
                onClick={() => this.handleFlip("buildingFlip")}
                className={classes.flipButton}
                fullWidth>
                <Typography color="textSecondary">{buildingFlip ? "floors" : "average"}</Typography>
                <ArrowRightAltIcon
                  className={classNames(classes.icon, {
                    [classes.rotated]: !buildingFlip
                  })}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* DEVICES */}
        <Grid item xs={12} sm={4} className={classNames(classes.header)}>
          <Card className={classes.card}>
            <Slide
              direction="right"
              in={devicesFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Devices Online
                </Typography>
                <Typography variant="h5" component="h2" className={classes.dataPoint}>
                  {dataPoints}
                </Typography>
                <Typography className={classes.status} color="textSecondary">
                  Reading
                </Typography>
              </CardContent>
            </Slide>
            <Slide
              direction="left"
              in={!devicesFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Data
                </Typography>
                <Paper elevation={0} className={classNames(classes.components, classes.datapoints)}>
                  {dataChips.map((component, idx) => (
                    <Typography
                      key={`${component}_${idx}__`}
                      variant="overline"
                      className={classes.pollingPoints}
                      style={{
                        fontSize: ".5em",
                        padding: ".5em"
                      }}>
                      {component}
                    </Typography>
                  ))}
                </Paper>
              </CardContent>
            </Slide>

            <CardActions className={classes.actionButton}>
              <Button
                onClick={() => this.handleFlip("devicesFlip")}
                fullWidth
                className={classes.flipButton}>
                <Typography color="textSecondary">
                  {devicesFlip ? "data points" : "total"}
                </Typography>
                <ArrowRightAltIcon
                  className={classNames(classes.icon, {
                    [classes.rotated]: !devicesFlip
                  })}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* STATS */}
        <Grid item xs={12} sm={4} className={classNames(classes.header)}>
          <Card className={classes.card}>
            <Slide
              direction="right"
              in={statusFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent onClick={() => reroute("/health")}>
                <Typography className={classes.chartTitle} color="textSecondary" gutterBottom>
                  Alarms
                </Typography>
                {/* RECHARTS */}
                {width === "xs" ? (
                  <PieChart width={175} height={75} clockWise={false} style={{ margin: "0 auto" }}>
                    <Pie
                      data={chartData.radial}
                      activeIndex={this.state.activeIndex}
                      activeShape={renderActiveShape}
                      label={renderCustomizedLabel}
                      clockWise={true}
                      innerRadius={20}
                      outerRadius={25}
                      startAngle={90}
                      endAngle={450}
                      dataKey="uv"
                      onMouseEnter={this.onPieEnter}
                      labelLine={false}
                    />
                  </PieChart>
                ) : (
                  <PieChart width={125} height={125} clockWise={false} style={{ margin: "0 auto" }}>
                    <Pie
                      data={chartData.radial}
                      activeIndex={this.state.activeIndex}
                      activeShape={renderActiveShape}
                      clockWise={true}
                      innerRadius={40}
                      outerRadius={50}
                      startAngle={90}
                      endAngle={450}
                      dataKey="uv"
                      onMouseEnter={this.onPieEnter}
                    />
                  </PieChart>
                )}
              </CardContent>
            </Slide>
            <Slide
              direction="left"
              in={!statusFlip}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide, classes.cardContentSlide)}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Alarm Status
                </Typography>
                <List className={classNames(classes.list, classes.buildingList)}>
                  {points.map((point, idx) => {
                    return (
                      <ListItem
                        key={`${point.name}_${point.value}_${idx}`}
                        className={classes.listItem}>
                        <ListItemText
                          classes={{
                            primary: classes.primaryText,
                            secondary: classes.secondaryText
                          }}
                          primary={String(point.time).toUpperCase()}
                          secondary={String(point.value).toUpperCase()}
                        />
                        <ListItemSecondaryAction>
                          {point.acknowledged || point.state === "NORMAL" ? (
                            <FlashOnIcon style={{ color: green[200] }} />
                          ) : (
                            <FlashOffIcon style={{ color: red[200] }} />
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
            </Slide>

            <CardActions className={classes.actionButton}>
              <Button
                onClick={() => this.handleFlip("statusFlip")}
                fullWidth
                className={classes.flipButton}>
                <Typography color="textSecondary">
                  {statusFlip && !emptySet ? `active: ${count}` : "radial"}
                </Typography>
                <ArrowRightAltIcon
                  className={classNames(classes.icon, {
                    [classes.rotated]: !statusFlip
                  })}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }

  handleFlip = card => {
    this.setState({ [card]: !this.state[card] });
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };
}

const styles = theme => ({
  header: {
    height: 250,
    [theme.breakpoints.down("xs")]: {
      padding: ".5em 0",
      height: 150
    }
  },
  card: {
    position: "relative",
    borderRadius: 0,
    padding: "1em",
    margin: "1em",
    height: "100%"
  },
  cardContentSlide: {},
  slide: {
    position: "absolute",
    left: 0,
    top: "10%",
    width: "100%",
    userSelect: "none",
    paddingTop: 0
  },
  dataPoint: {
    fontSize: "3em",
    textAlign: "center",
    userSelect: "none"
  },
  title: {
    fontSize: "1em",
    fontVariant: "all-small-caps",
    letterSpacing: ".15em",
    paddingBottom: "1em",
    textAlign: "center",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0
    }
  },
  chartTitle: {
    fontSize: "1em",
    fontVariant: "all-small-caps",
    letterSpacing: ".15em",
    textAlign: "center",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0
    }
  },
  datapoints: {
    height: 120,
    overflow: "hidden",
    overflowY: "scroll"
  },
  status: {
    marginBottom: 12,
    color: green[500],
    fontVariant: "all-small-caps",
    textAlign: "center",
    userSelect: "none"
  },
  listItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("sm")]: {
      fontSize: ".25em"
    }
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  listItem: {
    padding: ".25em",
    display: "flex",
    flexBasis: "75%",
    overflow: "hidden"
  },
  buildingList: {
    padding: ".5em 0",
    maxHeight: 125,
    [theme.breakpoints.down("xs")]: {
      maxHeight: 40
    }
  },
  components: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      height: 50,
      overflow: "scroll"
    }
  },
  pollingPoints: {
    color: theme.palette.secondary.light
  },
  flipButton: {
    position: "absolute",
    justifyContent: "space-between",
    padding: "1em 2em",
    fontSize: ".5em",
    margin: 0,
    borderRadius: 0,
    bottom: 0,
    left: 0
  },
  icon: {
    transition: "transform .1s ease-in-out",
    color: theme.palette.text.secondary
  },
  rotated: {
    transform: "rotate(180deg)"
  },
  primaryText: {
    fontSize: ".75em"
  },
  secondaryText: {
    fontSize: ".5em"
  }
});

DashboardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
  reroute: route => dispatch(push(route))
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles),
  withWidth()
)(DashboardHeader);
