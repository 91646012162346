import merge from "deepmerge";
import { cloneDeep, isEmpty, orderBy, split } from "lodash";

const addData = data => {
  if (!data.Units || !data.Type) {
    if (data.Type && data.Type === "tapa.Calendar") {
      return {
        displayString: data.DisplayName,
        data: data.Schedule,
        dataType: "calendar"
      };
    } else {
      return null;
    }
  } else if (data.Units.startsWith("temperature")) {
    if (data.Units === "temperature:fahrenheit:°F") {
      return { displayString: `${data.Value} °F`, dataType: "temp" };
    } else if (data.Units === "temperature:celcius:°C") {
      return { displayString: `${data.Value} °C`, dataType: "temp" };
    }
  } else if (data.Units.startsWith("dimensionless")) {
    if (data.Units === "dimensionless:percent:%") {
      return { displayString: `${data.Value} %`, dataType: "percent" };
    } else {
      return { displayString: `${data.Value}.`, dataType: null };
    }
  } else if (data.Units.startsWith("trueText")) {
    if (data.Units === "trueText='TRUE';falseText='FALSE'") {
      let name = `${String(data.Value)
        .charAt(0)
        .toUpperCase()}${String(data.Value).slice(1)}`;
      return { displayString: name, dataType: "bool" };
    } else if (data.Units === "trueText='ON';falseText='OFF'") {
      let result = data.Value ? "On" : "Off";
      return { displayString: result, dataType: "bool" };
    }
  } else {
    return "";
  }
};

const dataNormalizer = data => {
  let path, mergeOb;

  const merged = data.points.reduce((map, i) => {
    path = pathParser(i.Path);

    let addedData = addData(i);
    let displayString = addedData ? addedData.displayString : "";
    let dataType = addedData ? addedData.dataType : "";
    let data = addedData ? addedData.data : "";

    mergeOb = {
      [path[2]]: {
        [path[3]]: {
          [path[4]]: {
            hashID: i.HashID,
            value: i.Value,
            units: i.Units,
            displayName: i.DisplayName,
            dataType,
            displayString,
            ...data
          }
        }
      }
    };

    map = merge(map, mergeOb);
    return map;
  }, {});

  return merged;
};

const pathParser = path => {
  const regex = /[/][a-zA-Z0-9]*/gm;
  let match, sanitized;
  let matches = [];

  while ((match = regex.exec(path)) !== null) {
    if (match.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    sanitized = match[0].substring(match[0].indexOf("/") + 1);
    matches.push(sanitized);
  }

  return matches;
};

const dataOrganizer = incomingData => {
  const temperature = [];
  const lights = [];
  const calendar = [];

  let pointPath;
  incomingData.points.forEach(point => {
    pointPath = split(point.Path, "/");

    let isTemp = false;
    let isLight = false;
    let isCalendar = false;

    pointPath.forEach(dir => {
      if (dir.startsWith("VAV")) {
        isTemp = true;
      } else if (dir.startsWith("nLight")) {
        isLight = true;
      } else if (dir.startsWith("eventsCalendar")) {
        isCalendar = true;
      }
    });

    if (isTemp) {
      temperature.push(point);
    } else if (isLight) {
      lights.push(point);
    } else if (isCalendar) {
      calendar.push(point);
    } else {
      console.log(point);
    }
  });

  const organizedTemp = organizeTemp(temperature);
  const organizedLights = organizeLights(lights);
  const organizedCalendars = organizeCalendar(calendar);

  let data = {
    temperature: organizedTemp,
    lights: organizedLights,
    calendars: organizedCalendars
  };

  return data;
};

const organizeTemp = temps => {
  let pointPath;
  let tempArray = temps.map(point => {
    pointPath = split(point.Path, "/");
    point["Floor"] = pointPath[3];
    point["Sector"] = pointPath[4];
    point["Device"] = pointPath[5];
    point["Type"] = pointPath[6];
    return point;
  });

  return orderBy(tempArray, ["Device"]);
};

const organizeLights = lights => {
  let pointPath;
  let lightArray = lights.map(point => {
    pointPath = split(point.Path, "/");
    point["Floor"] = pointPath[3];
    point["Sector"] = pointPath[4];
    point["Device"] = pointPath[5];
    point["Type"] = pointPath[6];
    return point;
  });

  return orderBy(lightArray, ["Device"]);
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const organizeCalendar = cals => {
  const now = new Date();
  const year = now.getFullYear();
  const month = months[now.getMonth()].toUpperCase();

  let pointPath;
  let data;
  let calArray = cals.map(point => {
    if (!isEmpty(point.Schedule)) {
      if (point.Schedule[year]) {
        if (point.Schedule[year][month]) {
          data = point.Schedule[year][month];
        } else {
          data = null;
        }
      } else {
        data = null;
      }
    } else {
      data = null;
    }

    pointPath = split(point.Path, "/");
    point["Floor"] = pointPath[3];
    point["Type"] = pointPath[4];
    point["Data"] = cloneDeep(data);
    return point;
  });

  return orderBy(calArray, ["Type"]);
};

export { dataNormalizer, dataOrganizer, pathParser };
