import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { history } from "../store";

//HOCs
import AuthUserContext from "../hocs/context";
import withAuthentication from "../hocs/withAuthentication";

//Router
import { ConnectedRouter } from "connected-react-router";
import routes from "../routes";

//Styles
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 0,
        border: "1px solid #7DA2A8"
      }
    }
  },
  palette: {
    primary: {
      main: "#F7F7F8"
    },
    secondary: {
      main: "#7DA2A8"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Montserrat", "Anton"].join(",")
  }
});

class App extends Component {
  render() {
    const { authUser } = this.props;

    return (
      <AuthUserContext.Provider value={authUser}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        </MuiThemeProvider>
      </AuthUserContext.Provider>
    );
  }
}

//Connect
const mapStateToProps = state => ({
  authUser: state.session.authUser
});

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  withTheme(),
  withAuthentication()
)(App);
