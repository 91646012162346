import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { isEmpty, orderBy } from "lodash";

//Routes
import { DASHBOARD } from "../routes";

//MUI
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import HistoryIcon from "@material-ui/icons/History";

import { withStyles } from "@material-ui/core/styles";

//Components
import SetpointControls from "../components/Temperature/SetpointControls";
import { START_POLLING, STOP_POLLING } from "../actions";

class Temperature extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data,
      name: "",
      sectorId: ""
    };
  }

  componentDidMount() {
    const { authUser, api, role, reroute, controlPolling } = this.props;

    if (!authUser || role !== "admin") {
      reroute(DASHBOARD);
    } else {
      controlPolling(START_POLLING, api, role);
    }
  }

  componentWillUnmount() {
    const { controlPolling } = this.props;
    controlPolling(STOP_POLLING);
  }

  componentDidUpdate(prevProps) {
    const { data, name } = this.props;

    if (prevProps.data !== data || prevProps.name !== name) {
      this.setState({
        data
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { data, name } = this.state;
    const model = this.modelTemperature(data);
    const mapModel = this.mapModelTemperature(classes, model);

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.wrapper}>
            <Typography variant="h4" className={classNames(classes.type, classes.title)}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.wrapper}>
            {!isEmpty(model) && mapModel}
          </Grid>
        </Grid>
      </div>
    );
  }

  modelTemperature = data => {
    let model = {};
    !isEmpty(data) &&
      data.temperature &&
      data.temperature.forEach(point => {
        if (!(point.Device in model)) {
          model[point.Device] = [];
          model[point.Device].push(point);
        } else {
          model[point.Device].push(point);
        }

        if (model[point.Device].length === 3) {
          model[point.Device] = orderBy(model[point.Device], ["DisplayName"], ["desc"]);
        }
      });
    return model;
  };

  mapModelTemperature = (classes, model) => {
    const { reroute } = this.props;
    let data;
    return (
      <List>
        {Object.keys(model).map((key, idx) => {
          data = model[key];
          return (
            <Grid container key={key} style={{ marginBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classNames(classes.type, classes.title)}>
                  {key}
                </Typography>
                {data.map(data => {
                  return (
                    <ListItem key={`${data.HashID}_${idx}`} style={{ padding: 0 }} disableGutters>
                      <SetpointControls
                        data={data}
                        name={data.DisplayName}
                        readOnly={data.DisplayName === "SpaceTemp"}
                      />
                      <IconButton onClick={() => reroute(`/temperature/history/${data.HashID}`)}>
                        <HistoryIcon />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </List>
    );
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  data: state.data.organizedData,
  role: state.user.role,
  router: state.router
});

const mapDispatchToProps = dispatch => ({
  controlPolling: (control, api, role) => dispatch({ type: control, api, role }),
  reroute: route => dispatch(push(route))
});

const styles = theme => ({
  container: {
    padding: "1em",
    maxWidth: 600
  },
  wrapper: {
    //
  },
  type: {
    letterSpacing: ".2em",
    fontVariant: "all-small-caps",
    fontWeight: "800"
  },
  title: {
    justifyContent: "center",
    textAlign: "center"
  },
  control: {
    display: "flex",
    margin: "1em 2em",
    justifyContent: "space-between"
  }
});

Temperature.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Temperature);
