import React, { Component } from "react";
import { connect } from "react-redux";

//Image
import FloorPlanAlpha from "./../static/specsuiteAlphaBlue.png";

//Hex Component
import { GridGenerator, Hexagon, HexGrid, HexUtils, Layout, Text } from "./Hex";
import configs from "./../api/hexConfig";
import { push } from "connected-react-router";

const mapStateToProps = state => ({
  state
});
const mapDispatchToProps = dispatch => ({
  reroute: () => dispatch(push("/"))
});

class ThermalMap extends Component {
  constructor(props) {
    super(props);
    const config = configs["rectangle"];
    const generator = GridGenerator.getGenerator(config.map);
    const hexagons = generator.apply(this, config.mapProps);
    this.state = { hexagons, config };
  }

  render() {
    const { hexagons, config } = this.state;
    const { reroute } = this.props;
    const layout = config.layout;
    const size = { x: layout.width, y: layout.height };

    const getFill = () => {
      let redOne = Math.round(Math.random()) > 0.5 ? "E" : "F";
      let redTwo = Math.round(Math.random()) > 0.5 ? "E" : "F";
      let green = Math.floor(Math.random() * 60) + 20;
      let blue = Math.floor(Math.random() * 30) + 10;
      return String(`#${redOne}${redTwo}${green}${blue}`);
    };

    const getOpacity = (x, y) => {
      let edges = y === 0 || y === 22;
      let upperCorner = x > 18 && y <= 7;
      if (edges || upperCorner) {
        return String(0.05);
      }
      return Math.abs(x) >= 0 && Math.abs(y) >= 1 && x < 20 && y < 22
        ? String(0.4)
        : String(0.6);
      // return Math.round(Math.random()) > 0.3 ? String(0.2) : String(0.3);
    };

    return (
      <HexGrid
        width={config.width}
        height={config.height}
        viewBox={config.viewBox}>
        <Layout
          size={size}
          flat={layout.flat}
          spacing={layout.spacing}
          origin={config.origin}>
          {hexagons.map((hex, i) => {
            const fillColor = getFill();
            const opacity = getOpacity(hex.q, hex.r);
            return (
              <Hexagon
                key={config.mapProps + i}
                q={hex.q}
                r={hex.r}
                s={hex.s}
                fill={fillColor}
                fillOpacity={opacity}>
                <Text>{HexUtils.getID(hex)}</Text>
              </Hexagon>
            );
          })}
          <image
            onClick={() => reroute()}
            href={FloorPlanAlpha}
            xlinkHref={FloorPlanAlpha}
            height={"100%"}
          />
        </Layout>
      </HexGrid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThermalMap);
