import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { chain, compact, isEmpty, orderBy } from "lodash";

//Routes
import { DASHBOARD } from "../routes";

//MUI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Components
import LightControls from "../components/Lighting/LightControls";
import ToggleControls from "../components/Lighting/ToggleControls";
import { START_POLLING, STOP_POLLING } from "../actions";

class Lights extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data,
      name: "",
      sectorId: ""
    };
  }

  componentDidMount() {
    const { authUser, api, role, reroute, controlPolling } = this.props;

    if (!authUser || role !== "admin") {
      reroute(DASHBOARD);
    } else {
      controlPolling(START_POLLING, api, role);
    }
  }

  componentWillUnmount() {
    const { controlPolling } = this.props;
    controlPolling(STOP_POLLING);
  }

  componentDidUpdate(prevProps) {
    const { data, name } = this.props;

    if (prevProps.data !== data || prevProps.name !== name) {
      this.setState({
        data
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { data, name } = this.state;

    const model = this.modelLights(data);
    const mapModel = this.mapModelLights(classes, model);

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.wrapper}>
            <Typography
              variant="h4"
              className={classNames(classes.type, classes.title)}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.wrapper}>
            {!isEmpty(model) && mapModel}
            {/* {!isEmpty(data) && this.mapSectors({ classes, data })} */}
          </Grid>
        </Grid>
      </div>
    );
  }

  modelLights = data => {
    let model = {};
    let prefix, group;
    !isEmpty(data.lights) &&
      data.lights.forEach(point => {
        prefix = point.DisplayName.match(/(?:(?!_\S).)*/gm);
        group = compact(prefix);
        point["UnitName"] = group[1];

        if (!(group[0] in model)) {
          model[group[0]] = [];
          model[group[0]].push(point);
        } else {
          model[group[0]].push(point);
        }

        model[group[0]] = orderBy(model[group[0]], ["DisplayName"], ["desc"]);
      });

    const sorted = chain(model)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();

    return sorted;
  };

  mapModelLights = (classes, model) => {
    let data;
    return (
      model && (
        <List>
          {Object.keys(model).map((key, idx) => {
            data = model[key];
            return (
              <Grid container key={key} style={{ marginBottom: "1em" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classNames(classes.type, classes.title)}>
                    {key}
                  </Typography>
                  {data.map(data => {
                    return data.UnitName.includes("Virtual") ? (
                      <ListItem
                        key={`${data.HashID}_${idx}`}
                        style={{ flexWrap: "wrap", padding: 0 }}
                        disableGutters>
                        <ToggleControls data={data} name={data.UnitName} />
                      </ListItem>
                    ) : (
                      <ListItem
                        key={`${data.HashID}_${idx}`}
                        style={{ flexWrap: "wrap", padding: 0 }}
                        disableGutters>
                        <LightControls data={data} name={data.UnitName} />
                      </ListItem>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </List>
      )
    );
  };

  mapSectors = ({ classes, data }) => {
    return (
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <List>
            {data.lights.map((data, idx) => {
              return (
                <ListItem
                  key={`${data.HashID}_${idx}`}
                  style={{ flexWrap: "wrap" }}
                  disableGutters>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      className={classNames(classes.type, classes.title)}>
                      {data.Floor}/{data.Device}/{data.DisplayName}/
                    </Typography>
                  </Grid>
                  <LightControls
                    data={data}
                    readOnly={data.DisplayName === "SpaceTemp"}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    );
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  data: state.data.organizedData,
  role: state.user.role,
  router: state.router
});

const mapDispatchToProps = dispatch => ({
  controlPolling: (control, api, role) =>
    dispatch({ type: control, api, role }),
  reroute: route => dispatch(push(route))
});

const styles = theme => ({
  container: {
    padding: "1em",
    maxWidth: 600
  },
  wrapper: {
    //
  },
  type: {
    letterSpacing: ".2em",
    fontVariant: "all-small-caps",
    fontWeight: "800"
  },
  title: {
    justifyContent: "center",
    textAlign: "center"
  },
  control: {
    display: "flex",
    flexWrap: "wrap",
    margin: "1em 2em",
    justifyContent: "space-between"
  }
});

Lights.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Lights);
