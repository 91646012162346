//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  fetchingData: false,
  updatingData: false,
  isPolling: false,
  data: {},
  organizedData: { alarms: [] },
  rawData: {}
};

function dataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_DATA_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    case ACTIONS.UPDATE_DATA_REDUCER: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.UPDATE_ORGANIZED_DATA: {
      return {
        ...state,
        organizedData: { ...state.organizedData, ...action.payload }
      };
    }

    //Specific Updates
    case ACTIONS.START_POLLING: {
      return { ...state, isPolling: true };
    }

    case ACTIONS.START_ALARMS_POLLING: {
      return { ...state, isPolling: true };
    }

    case ACTIONS.STOP_POLLING: {
      return { ...state, isPolling: false };
    }

    case ACTIONS.STOP_ALARMS_POLLING: {
      return { ...state, isPolling: false };
    }

    default:
      return state;
  }
}

export default dataReducer;
