import { put, takeLatest } from "redux-saga/effects";

//Actions
import * as ACTIONS from "../actions";

//Functions
import { functions } from "../firebase/firebase";

function* handleHistory(action) {
  const { hashID, payload } = action;
  const { start, end } = payload;

  const getHistory = functions.httpsCallable("getHistory");

  const data = yield getHistory({ hashID, start, end }).then(result => {
    return result;
  });

  if (data) {
    yield put({
      type: ACTIONS.UPDATE_HISTORY_ASPECT,
      aspect: "data",
      payload: data.data.data
    });
  }
}

export default function* historySaga() {
  yield takeLatest(ACTIONS.HISTORY_SAGA, handleHistory);
}
