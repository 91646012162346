import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { deepOrange } from "@material-ui/core/colors";

//Components
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";
import { DATA_SAGA } from "../../actions";

class SetpointControls extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data,
      name: props.state,
      value: Math.round(props.data.Value)
    };
  }

  componentDidMount() {
    const { data, name } = this.props;
    this.setState({ data, name, value: Number(data.Value) });
  }

  componentDidUpdate(prevProps) {
    const { data, name } = this.props;

    if (prevProps.data !== data || prevProps.name !== name) {
      this.setState({ data, name, value: Number(data.Value) });
    }
  }

  render() {
    const { classes, data, readOnly } = this.props;
    const { name, value } = this.state;

    return (
      <Grid item xs={12} className={classes.control}>
        <Typography className={classNames(classes.type)} variant="h6">
          {name}
        </Typography>
        <Grid item xs={12} className={classes.wrapper}>
          <Slider
            min={readOnly ? 0 : 68}
            max={readOnly ? 100 : 76}
            disabled={readOnly}
            value={value}
            step={1}
            onChange={this.onSliderChange(data)}
            className={classes.slider}
            handleStyle={[
              { border: name === "OccHeatSP" && `2px solid ${deepOrange[300]}` }
            ]}
            trackStyle={{
              backgroundColor: name === "OccHeatSP" && deepOrange[300]
            }}
          />
        </Grid>
      </Grid>
    );
  }

  onSliderChange = data => value => {
    const { api, role, triggerSetpointChange } = this.props;
    this.setState({
      value
    });

    triggerSetpointChange({
      value,
      hashID: data.HashID,
      cmd: "write-value",
      role,
      api
    });
  };
}

const styles = theme => ({
  type: {
    letterSpacing: ".2em",
    fontVariant: "all-small-caps",
    fontWeight: "bolder"
  },
  control: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontWeight: "bolder"
  }
});

const mapStateToProps = state => ({
  role: state.user.role,
  api: state.user.api
});

const mapDispatchToProps = dispatch => ({
  triggerSetpointChange: ({ api, value, hashID, cmd, role }) =>
    dispatch({
      type: DATA_SAGA,
      filter: "changeSetpoint",
      payload: { api, value, hashID, cmd, role }
    })
});

SetpointControls.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SetpointControls);
