import React, { Component } from "react";
import { connect } from "react-redux";

//Image
import FloorPlan from "./../static/mapLayerv2.png";

//Hex Component
import { HexGrid, Layout } from "../components/Hex";
import configs from "./../api/hexConfig";
import { push } from "connected-react-router";

class Map extends Component {
  constructor(props) {
    super(props);
    const config = configs["rectangle"];
    this.state = { config };
  }

  render() {
    const { config } = this.state;
    const { reroute } = this.props;
    const layout = config.layout;
    const size = { x: layout.width, y: layout.height };

    return (
      <HexGrid
        width={config.width}
        height={config.height}
        viewBox={config.viewBox}>
        <Layout
          size={size}
          flat={layout.flat}
          spacing={layout.spacing}
          origin={config.origin}>
          <image href={FloorPlan} height={"100%"} onClick={() => reroute()} />
        </Layout>
      </HexGrid>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    reroute: () => dispatch(push("/"))
  })
)(Map);
