import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { doLogOut } from "../../firebase/auth";

//MUI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Icons
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

//Mask
import * as ACTIONS from "../../actions";
import { DASHBOARD, PROFILE, SUITE_SCREEN } from "../../routes";
import { push } from "connected-react-router";

class UserCard extends Component {
  state = {
    isFlipped: true,
    showPassword: false,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    loginEmail: "",
    loginPassword: ""
  };

  render() {
    const { classes, reroute, role } = this.props;

    return (
      <Grid
        container
        style={{ justifyContent: "flex-end", position: "relative" }}>
        <Card className={classes.card}>
          <CardHeader
            className={classNames(classes.cardHeader)}
            title={"Menu"}
            subheader={"You're logged in! Navigate below to continue."}
          />
          {/* Default */}
          <CardContent className={classNames(classes.cardContent)}>
            <Grid container>
              {role && role === "admin" ? (
                <Grid item xs={12} className={classes.menuItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.button}
                    onClick={() => reroute(PROFILE)}>
                    <Typography>Profile</Typography>
                    <ArrowRightAltIcon className={classNames(classes.icon)} />
                  </Button>
                </Grid>
              ) : null}
              {role && role === "admin" ? (
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.menuItem}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.button}
                      onClick={() => reroute(DASHBOARD)}>
                      <Typography>Dashboard</Typography>
                      <ArrowRightAltIcon className={classNames(classes.icon)} />
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              {(role && role === "admin") || role === "screen" ? (
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.menuItem}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.button}
                      onClick={() => reroute(SUITE_SCREEN)}>
                      <Typography>Suite Screen</Typography>
                      <ArrowRightAltIcon className={classNames(classes.icon)} />
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>

          <Button
            onClick={() => this.handleLogout()}
            fullWidth
            className={classes.flipButton}>
            <Typography>logout</Typography>
          </Button>
        </Card>
      </Grid>
    );
  }

  handleLogout = () => {
    const { resetApp } = this.props;
    doLogOut();
    resetApp();
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  role: state.user.role
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => {
    dispatch({
      type: ACTIONS.UPDATE_SESSION_ASPECT,
      aspect: "error",
      payload: ""
    });
  },
  reroute: route => {
    dispatch(push(route));
  },
  resetApp: () =>
    dispatch({
      type: ACTIONS.RESET_APP
    })
});

const styles = theme => ({
  card: {
    height: 575,
    maxWidth: 345,
    padding: "2em",
    margin: "0 2em 2em 2em",
    position: "relative",
    borderRadius: 0
  },
  cardHeader: {
    height: "100px"
  },
  cardContent: {
    height: 400,
    position: "relative"
  },
  menuItem: {
    paddingBottom: "1.5em"
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 0,
    padding: "1em"
  },
  flipButton: {
    justifyContent: "center",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  }
});

UserCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserCard);
