import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  card: {
    margin: "0 1rem 1rem"
  },
  buttonLabel: {
    display: "flex",
    position: "relative"
  },
  icon: {
    position: "absolute",
    display: "flex",
    justifyItems: "center",
    right: 0
  },
  media: {
    height: 140,
    [theme.breakpoints.up("sm")]: {
      height: 200
    }
  }
});

function DataSelectorCard(props) {
  const { classes, buttonText, buttonRoute, cardMedia, cardTitle, icon, reroute } = props;
  return (
    <Card className={classes.card} square>
      <CardActions style={{ justifyContent: "center", color: "black" }}>
        <Button
          fullWidth
          size="small"
          color="secondary"
          style={{ fontSize: "1.1rem", letterSpacing: ".25rem" }}
          onClick={() => reroute(buttonRoute)}
          classes={{ label: classes.buttonLabel }}>
          {buttonText}
          {icon && <Grid className={classes.icon}>{icon}</Grid>}
        </Button>
      </CardActions>
      <CardActionArea>
        <CardMedia
          onClick={() => reroute(buttonRoute)}
          className={classes.media}
          image={cardMedia}
          title={cardTitle}
        />
      </CardActionArea>
    </Card>
  );
}

DataSelectorCard.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonRoute: PropTypes.string.isRequired,
  cardMedia: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  cardText: PropTypes.string,
  reroute: PropTypes.func.isRequired
};

export default withStyles(styles)(DataSelectorCard);
