//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  data: []
};

function dataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_HISTORY_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    default:
      return state;
  }
}

export default dataReducer;
