import React, { Component } from "react";
import { connect } from "react-redux";

import { DATA_SAGA } from "../actions";
import { watchUser } from "../firebase/db";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { push } from "connected-react-router";
import { compact } from "lodash";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CalendarGraphic from "./../static/calendarGraphicNew.png";
import CalendarGraphicSuites from "./../static/calendarGraphicSuites.png";

class Schedule extends Component {
  constructor(props) {
    super();
    this.state = {
      authUser: props.authUser,
      data: props.data
    };
  }

  componentDidMount() {
    const { authUser, api, role, reroute, getData } = this.props;
    if (!authUser || role !== "admin") reroute("/");
    this.setState({ authUser });
    authUser && getData(api, role);
  }

  componentDidUpdate(prevProps) {
    const { authUser, api, data, dispatch, role, getData } = this.props;

    if (prevProps.authUser !== authUser) {
      authUser && watchUser({ dispatch, uid: authUser.uid });
      authUser && getData(api, role);
    } else if (prevProps.data !== data) {
      this.setState({ data });
    }
  }

  render() {
    const { data } = this.props;
    let calendar;

    //Data nullcheck
    if (!data["rooms"]) return null;

    const rooms = Object.keys(data["rooms"]).map(room => {
      calendar = data["rooms"][room].eventsCalendar;
      console.log(room);
      console.log(calendar);
      return (
        <Grid
          container
          style={{ padding: "0 4em", backgroundColor: "white" }}
          key={calendar.hashID}
        >
          <Grid item xs={12} style={{ justifyContent: "center" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "1.5em"
              }}
            >
              {calendar.displayName.toUpperCase()}
            </Typography>
          </Grid>

          {this.getCalendarData(calendar)}
        </Grid>
      );
    });

    return (
      <Paper
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => this.props.reroute}
      >
        <Carousel
          infiniteLoop
          autoPlay
          interval={4000}
          transitionTime={500}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
        >
          <img src={CalendarGraphic} width={"100%"} alt={"Schedule"} />
          <img src={CalendarGraphicSuites} width={"100%"} alt={"Schedule"} />
        </Carousel>
        <Grid container>
          <Grid item xs={12}>
            <Carousel
              infiniteLoop
              autoPlay
              interval={8000}
              transitionTime={500}
              showIndicators={false}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
            >
              {rooms}
            </Carousel>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  getCalendarData(data) {
    const monthArray = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december"
    ];
    const year = compact(
      Object.keys(data).map(datum => {
        if (datum.startsWith("2018") || datum.startsWith("2019")) {
          return datum;
        } else {
          return null;
        }
      })
    );

    const months = year.map((yr, idx) => {
      return (
        <div key={yr + idx} style={{ width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                fonntSize: "1.1em",
                letterSpacing: ".1em",
                padding: ".5em 0",
                borderBottom: "1px solid blue",
                width: "25%"
              }}
            >
              {yr}
            </Typography>

            <Grid container>
              {Object.keys(data[yr]).map(mo => {
                let thisMonth = new Date().getMonth();
                let calMo = monthArray.indexOf(mo.toLowerCase());
                if (calMo >= thisMonth || true) {
                  let days = data[yr][mo];
                  return Object.keys(days).map(day => {
                    return (
                      <Grid
                        key={day}
                        item
                        xs={12}
                        style={{ fontWeight: "bold" }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                            paddingTop: "1em"
                          }}
                        >
                          {mo} {day}
                        </Typography>
                        <Grid container>
                          <Grid item xs={4}>
                            Start
                          </Grid>
                          <Grid item xs={4}>
                            End
                          </Grid>
                          <Grid item xs={4}>
                            Booked By
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{ flexDirection: "column-reverse" }}
                        >
                          {days[day].map((schedule, idx) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                key={idx}
                                style={{
                                  letterSpacing: ".15em",
                                  fontSize: ".5em",
                                  verticalAlign: "middle",
                                  display: "flex"
                                }}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    style={{
                                      fontSize: "1.5em",
                                      letterSpacing: ".15em"
                                    }}
                                  >
                                    {schedule.startTime}
                                  </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography
                                    style={{
                                      fontSize: "1.5em",
                                      letterSpacing: ".15em"
                                    }}
                                  >
                                    {schedule.endTime}
                                  </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography
                                    style={{
                                      fontSize: "1.5em",
                                      letterSpacing: ".15em"
                                    }}
                                  >
                                    {schedule.value.ownerFirstName}{" "}
                                    {schedule.value.ownerLastName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    );
                  });
                } else {
                  return null;
                }
              })}
            </Grid>
          </div>
        </div>
      );
    });

    return months;
  }
}

export default connect(
  state => ({
    authUser: state.session.authUser,
    api: state.user.api,
    role: state.user.role,
    data: state.data.data,
    rawData: state.data.rawData
  }),
  dispatch => ({
    dispatch,
    reroute: () => dispatch(push("/")),
    getData: (api, role) =>
      dispatch({
        type: DATA_SAGA,
        api,
        role
      }),
    rerouteWithProps: (route, props) => dispatch(push(route, props))
  })
)(Schedule);
