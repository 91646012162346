import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { DATA_SAGA } from "../actions";
import { watchUser } from "../firebase/db";
import { compact } from "lodash";

//MUI
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Grid
import { GridGenerator, HexGrid, Layout, Hexagon } from "react-hexgrid";
import * as d3 from "d3";

//Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Images
import HeatmapOverlay from "../static/heatmapOverlay.png";
import HeatmapOverlayTop from "../static/heatmapOverlayTop.png";
import CalendarGraphic from "./../static/calendarGraphic.png";
import CalendarGraphicSuites from "./../static/calendarGraphicSuites.png";

class SuiteScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      authUser: props.authUser,
      organizedData: props.data
    };
  }

  componentDidMount() {
    const { authUser, api, role, reroute, getData } = this.props;
    if (!authUser) reroute("/");
    this.setState({ authUser });
    authUser && getData(api, role);
  }

  componentDidUpdate(prevProps) {
    const {
      authUser,
      api,
      organizedData,
      dispatch,
      role,
      getData
    } = this.props;

    if (prevProps.authUser !== authUser) {
      authUser && watchUser({ dispatch, uid: authUser.uid });
      authUser && getData(api, role);
    } else if (prevProps.organizedData !== organizedData) {
      this.setState({ organizedData });
    }
  }

  render() {
    const { classes, data, fetchingData } = this.props;
    const { organizedData } = this.state;

    const indices = [
      714,
      940,
      1165,
      1168,
      943,
      898,
      718,
      583,
      535,
      447,
      126,
      355,
      532,
      208,
      121,
      116,
      112,
      198,
      99,
      108,
      104,
      317,
      452,
      457,
      592,
      728,
      586,
      863,
      721,
      855,
      906,
      1039,
      1044
    ];

    // Return progress if loading
    if (fetchingData || !organizedData.temperature) {
      return (
        <LinearProgress color="secondary" style={{ marginTop: "3.5em" }} />
      );
    }
    const temps = organizedData.temperature.filter(
      a =>
        Number(a.Device.match(/[0-9][0-9]/i)[0]) >= 17 &&
        Number(a.Device.match(/[0-9][0-9]/i)[0]) <= 49 &&
        a.DisplayName === "SpaceTemp"
    );
    const vavMap = this.vavMap(temps);

    const minMax = this.findMinMax(temps);

    const ryb = d3.scaleSequential(d3.interpolateRdYlBu);
    const linearScale = d3
      .scaleLinear()
      .domain([minMax[0], minMax[1]])
      .range([1, 0]);

    const hexas = GridGenerator.rectangle(45, 28);
    const hexTemp = this.mapTempToHex(hexas, vavMap, temps, indices);

    const neighborMap = () => {
      const hexes = [];
      hexTemp.forEach((hex, i) => {
        if (hex.val !== 0) {
          hexes.push(
            <Hexagon
              key={i}
              q={hex.q}
              r={hex.r}
              s={hex.s}
              cellStyle={{
                fill: ryb(linearScale(hex.val)),
                fillOpacity: 0.5
              }}
              onMouseEnter={() => console.log("Key: ", i, "Hex: ", hex)}
            />
          );

          for (let i = -5; i < 5; i++) {
            for (let j = -5; j < 5; j++) {
              hexes.push(
                <Hexagon
                  key={`alt_${i}${Math.random()}`}
                  q={hex.q + i}
                  r={hex.r + j}
                  s={-hex.q + i - (hex.r + j)}
                  cellStyle={{
                    fill: ryb(
                      Math.random() > 0.2
                        ? linearScale(hex.val)
                        : Math.random() > 0.5
                        ? linearScale(hex.val) +
                          Math.random() * linearScale(hex.val)
                        : linearScale(hex.val) -
                          Math.random() * linearScale(hex.val)
                    ),
                    fillOpacity: Math.random() > 0.5 ? 0.1 : 0.15
                  }}
                  onMouseEnter={() => console.log("Key: ", i, "Hex: ", hex)}
                />
              );
            }
          }
        }
      });
      return hexes;
    };

    let calendar;
    let name;

    //Data nullcheck
    if (!data["rooms"]) return null;

    const rooms = Object.keys(data["rooms"]).map(room => {
      calendar = data["rooms"][room].eventsCalendar;
      name = calendar.displayName.toUpperCase();
      if (
        name.includes("SHAW") ||
        name.includes("BROOKLAND") ||
        name.includes("LOGAN")
      ) {
        return (
          <Grid
            container
            style={{ padding: "0 4em", backgroundColor: "#0868BF" }}
            key={calendar.hashID}>
            <Grid
              item
              xs={12}
              style={{
                justifyContent: "center",
                color: "white",
                backgroundColor: "#0868BF"
              }}>
              <Typography
                variant="h6"
                style={{
                  backgroundColor: "#0868BF",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "1.5em"
                }}>
                {calendar.displayName.toUpperCase()}
              </Typography>
            </Grid>

            {this.getCalendarData(calendar)}
          </Grid>
        );
      } else return null;
    });

    return (
      <Grid style={{ backgroundColor: "#0868BF" }}>
        <Paper
          style={{
            width: "1351px",
            position: "absolute",
            backgroundColor: "#0868BF"
          }}
          onClick={() => this.props.reroute}>
          <Carousel
            style={{ backgroundColor: "#0868BF" }}
            infiniteLoop
            autoPlay
            interval={8000}
            transitionTime={500}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            showThumbs={false}>
            <Grid
              className={classes.container}
              style={{ backgroundColor: "#0868BF" }}>
              <img
                className={classes.image}
                src={HeatmapOverlay}
                alt="heatmap overlay"
                width={1351}
                height="auto"
              />
              <Grid className={classes.heatmap}>
                <HexGrid width={1325} height={700} viewBox="35 0 100 100">
                  <Layout
                    size={{ x: 2, y: 2 }}
                    flat={false}
                    spacing={1}
                    origin={{ x: 0, y: 10 }}>
                    {neighborMap()}
                  </Layout>
                </HexGrid>
              </Grid>
              <img
                className={classes.image}
                src={HeatmapOverlayTop}
                alt="heatmap overlay"
                width={1351}
                height="auto"
              />
            </Grid>
            <img src={CalendarGraphic} width={"100%"} alt={"Schedule"} />
            <img src={CalendarGraphicSuites} width={"100%"} alt={"Schedule"} />
          </Carousel>
          <Grid
            container
            style={{ marginTop: "-55px", backgroundColor: "#0868BF" }}>
            <Grid item xs={12}>
              <Carousel
                infiniteLoop
                autoPlay
                interval={4000}
                transitionTime={500}
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                showThumbs={false}>
                {compact(rooms)}
              </Carousel>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  vavMap = temps => {
    // IDX, Q, R, S = -Q-R
    const deviceMap = [
      [714, 32, 15],
      [940, 30, 20],
      [1165, 28, 25],
      [1168, 31, 25],
      [943, 33, 20],
      [898, 34, 19],
      [718, 36, 15],
      [583, 37, 12],
      [535, 35, 11],
      [447, 38, 9],
      [126, 35, 2],
      [355, 37, 7],
      [532, 32, 11],
      [208, 26, 4],
      [121, 30, 2],
      [116, 25, 2],
      [112, 21, 2],
      [198, 16, 4],
      [99, 8, 2],
      [108, 17, 2],
      [104, 13, 2],
      [317, -1, 7],
      [452, -3, 10],
      [457, 2, 10],
      [592, 1, 13],
      [728, 0, 16],
      [586, -5, 13],
      [863, -1, 19],
      [721, -7, 16],
      [855, -9, 19],
      [906, -4, 20],
      [1039, -7, 23],
      [1044, -2, 23]
    ];

    const devices = [];

    temps.forEach((point, idx) => {
      devices.push({
        idx: deviceMap[idx][0],
        q: deviceMap[idx][1],
        r: deviceMap[idx][2],
        s: -deviceMap[idx][1] - deviceMap[idx][2],
        val: Number(point.Value)
      });
    });

    return devices;
  };

  findMinMax = arr => {
    let min = arr[0].Value,
      max = arr[0].Value;

    for (let i = 1, len = arr.length; i < len; i++) {
      let Value = arr[i].Value;
      if (Number(Value) > 500) {
      } else {
        min = Value < min ? Value : min;
        max = Value > max ? Value : max;
      }
    }

    return [min, max];
  };

  mapTempToHex = (hexas, vavMap, temps, indices) => {
    const mappedHexas = hexas.map((hex, idx) => {
      const index = indices.indexOf(idx);
      if (index !== -1) {
        hex["val"] = temps[index].Value !== "999.00" ? temps[index].Value : 0;
      } else {
        hex["val"] = 0;
      }
      return hex;
    });
    return mappedHexas;
  };

  getCalendarData(data) {
    const monthArray = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december"
    ];
    const year = compact(
      Object.keys(data).map(datum => {
        if (datum.startsWith(String(new Date().getFullYear()))) {
          return datum;
        } else {
          return null;
        }
      })
    );

    const months = year.map((yr, idx) => {
      return (
        <div key={yr + idx} style={{ width: "100%" }}>
          {yr === String(new Date().getFullYear()) && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Typography
                variant="h6"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.1em",
                  letterSpacing: ".1em",
                  padding: ".5em 0",
                  borderBottom: "1px solid blue",
                  width: "25%"
                }}>
                {yr}
              </Typography>

              <Grid container>
                {Object.keys(data[yr]).map(mo => {
                  let thisMonth = new Date().getMonth();
                  let calMo = monthArray.indexOf(mo.toLowerCase());
                  if (calMo >= thisMonth) {
                    let days = data[yr][mo];
                    return Object.keys(days).map(day => {
                      let thisDay = new Date().getDate();
                      if (thisDay - 5 > day) return null;

                      return (
                        <Grid
                          key={day}
                          item
                          xs={12}
                          style={{ fontWeight: "bold" }}>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.25rem",
                              paddingTop: "1em"
                            }}>
                            {mo} {day}
                          </Typography>
                          <Grid container>
                            <Grid item xs={4} style={{ color: "white" }}>
                              Start
                            </Grid>
                            <Grid item xs={4} style={{ color: "white" }}>
                              End
                            </Grid>
                            <Grid item xs={4} style={{ color: "white" }}>
                              Booked By
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{ flexDirection: "column-reverse" }}>
                            {days[day].map((schedule, idx) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  key={idx}
                                  style={{
                                    letterSpacing: ".15em",
                                    fontSize: ".5em",
                                    verticalAlign: "middle",
                                    display: "flex"
                                  }}>
                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.startTime}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.endTime}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.value.ownerFirstName}{" "}
                                      {schedule.value.ownerLastName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    });
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </div>
          )}
        </div>
      );
    });

    return months;
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  data: state.data.data,
  organizedData: state.data.organizedData,
  fetchingData: state.data.fetchingData,
  role: state.user.role,
  scheme: state.app.scheme
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getData: (api, role) =>
    dispatch({
      type: DATA_SAGA,
      api,
      role
    }),
  reroute: route => dispatch(push(route)),
  rerouteWithProps: (route, props) => dispatch(push(route, props))
});

const styles = theme => ({
  container: {
    position: "relative",
    width: "100%",
    backgroundColor: "#0868BF"
  },
  image: {
    position: "absolute",
    left: 0
  },
  heatmap: {
    position: "absolute",
    width: 1351,
    height: 715
  }
});

SuiteScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  reroute: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SuiteScreen);
