//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  didFetchUser: false,
  api: "",
  profile: {
    email: "",
    firstName: "",
    lastName: ""
  },
  role: "user"
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_USER_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    case ACTIONS.UPDATE_USER_REDUCER: {
      return { ...state, ...action.payload };
    }

    //Specific Updates

    default:
      return state;
  }
}

export default userReducer;
