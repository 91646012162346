import React from "react";
import { Sector } from "recharts";
import * as UTIL from "../../util";
import { isEmpty } from "../../util/ob";

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill
  } = props;

  return (
    <g key={props.label}>
      <text fontSize={12} x={cx} y={cy} dy={5} textAnchor="middle" fill={fill}>
        {/* {props.total} */}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const renderCustomizedLabel = props => {
  const { cx, midAngle, innerRadius, outerRadius, percent } = props;
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 10) * cos;
  const ex = mx + (cos >= 0 ? 1 : -1);

  return percent === 1 || percent === 0 ? null : (
    <text
      fontSize="10"
      x={ex + (cos >= 0 ? 1 : -1)}
      y={4}
      fill={props.color}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {props.name.toUpperCase()}
    </text>
  );
};

const getDataPointNumber = data => {
  let num = 0;
  Object.keys(data).forEach(sector => {
    if (sector.includes("Floor")) {
      Object.keys(data[sector]).forEach(sect => {
        num += Object.keys(data[sector][sect]).length;
      });
    }
  });

  return num;
};

const getDataChips = data => {
  let points = [];
  Object.keys(data).forEach(name => {
    Object.values(data[name]).forEach(component => {
      Object.keys(component).forEach(vav => {
        if (!points.includes(component[vav].displayName)) {
          points.push(component[vav].displayName);
        }
      });
    });
  });
  return points;
};

const generateChartData = data => {
  let acknowledged = 0,
    pending = 0,
    total = 0;

  data &&
    data.forEach(alarm => {
      if (alarm.acknowledged || alarm.state === "NORMAL") {
        acknowledged += 1;
      } else {
        pending += 1;
      }
    });

  total = acknowledged + pending;

  const radial = [
    {
      uv: acknowledged,
      name: "acknowledged",
      color: "#82CA9D",
      fill: "#82CA9D",
      label: `${Math.round((acknowledged / total) * 100)}%`,
      domain: [0, acknowledged],
      total: total
    },
    {
      uv: pending,
      name: "pending",
      color: "#FF8042",
      fill: "#FF8042",
      label: `${(pending / total) * 100}%`,
      domain: [0, pending],
      total: total
    }
  ];

  const meta = {
    label: `${Math.round((acknowledged / total) * 100)}%`
  };

  return { radial, meta };
};

const getDevices = data => {
  let devices = [];
  Object.keys(data).forEach(name => {
    Object.values(data[name]).forEach(component => {
      Object.keys(component).forEach(vav => {
        devices.push({
          vav
        });
      });
    });
  });
  return devices;
};

const getPoints = data => {
  const points = data.map(alarm => {
    return {
      time: `${new Date(alarm.timestamp).toLocaleDateString()} ${new Date(
        alarm.timestamp
      ).toLocaleTimeString()}`,
      acknowledged: alarm.acknowledged || alarm.state === "NORMAL",
      value: !isEmpty(alarm.meta)
        ? `Current Value: ${Math.round(alarm.meta.alarmValue)}`
        : alarm.state
    };
  });

  return points.sort(UTIL.ARR.compare("time"));
};

export {
  renderActiveShape,
  renderCustomizedLabel,
  getPoints,
  getDataPointNumber,
  getDataChips,
  getDevices,
  generateChartData
};
