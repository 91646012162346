//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  logan: false,
  shaw: false,
  brookland: false
};

function scheduleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_AVAILABILITY: {
      return {
        ...state,
        [`${action.room}`]: action.payload
      };
    }
    default:
      return state;
  }
}

export default scheduleReducer;
