import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { isEmpty } from "lodash";

//Routes
import { DASHBOARD } from "../routes";

//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Components
import HealthTable from "../components/Health/HealthTable";
import {
  DATA_SAGA,
  START_ALARMS_POLLING,
  STOP_ALARMS_POLLING
} from "../actions";

class Health extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data
    };
  }

  componentDidMount() {
    const { authUser, api, role, reroute, controlPolling } = this.props;

    if (!authUser || role !== "admin") {
      reroute(DASHBOARD);
    } else {
      controlPolling(START_ALARMS_POLLING, api, role);
    }
  }

  componentWillUnmount() {
    const { controlPolling } = this.props;
    controlPolling(STOP_ALARMS_POLLING);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (prevProps.data !== data) {
      this.setState({
        data
      });
    }
  }

  render() {
    const { classes, acknowledge } = this.props;
    const { data } = this.state;

    return (
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">Alarms</Typography>
        </Grid>
        <Grid item xs={12}>
          {isEmpty(data) ? (
            <Grid item xs={12}>
              <Typography variant="h6">No Active Alarms</Typography>
            </Grid>
          ) : (
            <HealthTable data={data} acknowledge={acknowledge} />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  data: state.data.organizedData.alarms,
  role: state.user.role,
  router: state.router
});

const mapDispatchToProps = dispatch => ({
  controlPolling: (control, api, role) =>
    dispatch({ type: control, api, role }),
  acknowledge: GUID =>
    dispatch({
      type: DATA_SAGA,
      filter: "acknowledge",
      GUID
    }),
  reroute: route => dispatch(push(route))
});

const styles = theme => ({
  container: {
    padding: "1em"
  }
});

Health.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Health);
