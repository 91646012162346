import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

//MUI
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { DATA_SAGA } from "../../actions";

class ToggleControls extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data,
      name: props.state,
      value: props.data.Value === "false" ? false : true
    };
  }

  componentDidMount() {
    const { data, name } = this.props;
    this.setState({ data, name, value: data.Value === "false" ? false : true });
  }

  componentDidUpdate(prevProps) {
    const { data, name } = this.props;

    if (prevProps.data !== data || prevProps.name !== name) {
      this.setState({ data, name, value: data.Value === "false" ? false : true });
    }
  }

  render() {
    const { classes } = this.props;
    const { name, value } = this.state;

    return (
      <Grid item xs={12} className={classes.control}>
        <Grid item xs={6} className={classes.container}>
          <Typography className={classNames(classes.type)} variant="h6">
            {name}
          </Typography>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <Switch
            checked={value}
            value={value}
            onChange={this.handleChange}
            className={classes.switch}
          />
        </Grid>
      </Grid>
    );
  }

  handleChange = event => {
    const { api, data, role, triggerSetpointChange } = this.props;
    this.setState({ value: event.target.checked });

    triggerSetpointChange({
      value: event.target.checked,
      hashID: data.HashID,
      cmd: "write-value",
      role,
      api
    });
  };
}

const styles = theme => ({
  type: {
    letterSpacing: ".2em",
    fontVariant: "all-small-caps"
  },
  control: {
    display: "flex",
    margin: "1em 2em",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  bold: {
    fontWeight: "bolder"
  }
});

const mapStateToProps = state => ({
  role: state.user.role,
  api: state.user.api
});

const mapDispatchToProps = dispatch => ({
  triggerSetpointChange: ({ api, value, hashID, cmd, role }) =>
    dispatch({
      type: DATA_SAGA,
      filter: "changeLights",
      payload: { api, value, hashID, cmd, role }
    })
});

ToggleControls.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ToggleControls);
