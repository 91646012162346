import { db } from "./firebase";

import * as ACTIONS from "../actions";

//For FIRST TIME SIGN UP ONLY
export const updateUser = ({ uid, payload }) => {
  return db
    .ref(`users/${uid}`)
    .update({
      ...payload
    })
    .then(res => res)
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const uploadUser = (uid, dispatch, data) => {
  const ref = db.ref(`users/${uid}`);

  return ref
    .set({
      api: data.api,
      profile: { email: data.email, firstName: data.firstName, lastName: data.lastName }
    })
    .then(() => {
      dispatch({
        type: ACTIONS.UPDATE_USER_REDUCER,
        payload: {
          api: data.api,
          profile: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName
          }
        }
      });

      return data;
    });
};

export const watchUser = ({ dispatch, uid }) => {
  return db.ref(`users/${uid}`).on("value", snapshot => {
    dispatch({
      type: ACTIONS.UPDATE_USER_REDUCER,
      payload: { ...snapshot.val() }
    });

    return snapshot.val();
  });
};
