import { call, delay, put, take, race } from "redux-saga/effects";
import * as ACTIONS from "../actions";

import { functions } from "../firebase/firebase";

function* fetchRoute(action) {
  while (true) {
    try {
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "pollingData",
        payload: true
      });

      const fetchRoute = functions.httpsCallable("fetchRoute");

      const data = yield fetchRoute({
        api: action.api,
        role: action.role,
        route: "alarms"
      }).then(result => {
        return JSON.parse(result.data);
      });

      yield put({
        type: ACTIONS.UPDATE_ORGANIZED_DATA,
        aspect: "alarms",
        payload: data
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "pollingData",
        payload: false
      });

      yield delay(10000);
    } catch (e) {
      yield put({ type: ACTIONS.ALARMS_POLLING_FAILURE, message: e.message });
      yield put({ type: ACTIONS.STOP_ALARMS_POLLING });
    }
  }
}

function* watchPollSaga() {
  while (true) {
    const data = yield take(ACTIONS.START_ALARMS_POLLING);
    yield race([call(fetchRoute, data), take(ACTIONS.STOP_ALARMS_POLLING)]);
  }
}

export default function* pollAlarmsSaga() {
  yield watchPollSaga();
}
