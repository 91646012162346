import { all } from "redux-saga/effects";

//Watchers
import authSaga from "./auth";
import dataSaga from "./data";
import historySaga from "./history";
import pollSaga from "./poll";
import pollAlarmsSaga from "./pollAlarms";

export default function* rootSaga() {
  yield all([authSaga(), pollSaga(), pollAlarmsSaga(), dataSaga(), historySaga()]);
}
