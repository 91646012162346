import React, { Component } from "react";
import { connect } from "react-redux";

import { DATA_SAGA } from "../actions";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { push } from "connected-react-router";
import { compact } from "lodash";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SpecsuiteSchedule from "./../static/specsNewBackground.png";

class Schedule extends Component {
  constructor(props) {
    super();
    this.state = {
      authUser: props.authUser,
      data: props.data
    };
  }

  render() {
    const { data } = this.props;
    let calendar;
    let name;
    //Data nullcheck
    if (!data["rooms"]) return null;

    const rooms = Object.keys(data["rooms"]).map(room => {
      calendar = data["rooms"][room].eventsCalendar;
      name = calendar.displayName.toUpperCase();
      if (
        name.includes("SHAW") ||
        name.includes("BROOKLAND") ||
        name.includes("LOGAN")
      ) {
        return (
          <Grid
            container
            style={{ padding: "0 4em", backgroundColor: "#0868BE" }}
            key={calendar.hashID}>
            <Grid item xs={12} style={{ justifyContent: "center" }}>
              <Typography
                variant="h6"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "1.5em"
                }}>
                {calendar.displayName.toUpperCase()}
              </Typography>
            </Grid>

            {this.getCalendarData(calendar)}
          </Grid>
        );
      } else return null;
    });

    return (
      <Paper
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "#0868BE",
          color: "white"
        }}
        onClick={() => this.props.reroute}>
        <img
          src={SpecsuiteSchedule}
          width={"1352px"}
          alt={"Schedule"}
          style={{ marginLeft: "-1px", paddingTop: 700 }}
        />
        <Grid
          container
          style={{
            color: "white",
            width: "1351px",
            backgroundColor: "#0868BE",
            marginTop: "-10px"
          }}>
          <Grid item xs={12}>
            <Carousel
              infiniteLoop
              autoPlay
              interval={8000}
              transitionTime={500}
              showIndicators={false}
              showStatus={false}
              showArrows={false}
              showThumbs={false}>
              {compact(rooms)}
            </Carousel>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  getCalendarData(data) {
    const monthArray = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december"
    ];
    const year = compact(
      Object.keys(data).map(datum => {
        if (datum.startsWith(String(new Date().getFullYear()))) {
          return datum;
        } else {
          return null;
        }
      })
    );

    const months = year.map((yr, idx) => {
      return (
        <div key={yr + idx} style={{ width: "100%" }}>
          {yr === String(new Date().getFullYear()) && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1em",
                  letterSpacing: ".1em",
                  padding: ".5em 0",
                  borderBottom: "1px solid white",
                  width: "25%",
                  color: "white"
                }}>
                {yr}
              </Typography>

              <Grid container>
                {Object.keys(data[yr]).map(mo => {
                  let thisMonth = new Date().getMonth();
                  let calMo = monthArray.indexOf(mo.toLowerCase());
                  if (calMo >= thisMonth) {
                    let days = data[yr][mo];
                    return Object.keys(days).map(day => {
                      let thisDay = new Date().getDate();
                      if (thisDay > day) return null;

                      return (
                        <Grid
                          key={day}
                          item
                          xs={12}
                          style={{ fontWeight: "bold" }}>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.25rem",
                              paddingTop: "1em",
                              color: "white"
                            }}>
                            {mo} {day}
                          </Typography>
                          <Grid container>
                            <Grid item xs={4}>
                              Start
                            </Grid>
                            <Grid item xs={4}>
                              End
                            </Grid>
                            <Grid item xs={4}>
                              Booked By
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{ flexDirection: "column-reverse" }}>
                            {days[day].map((schedule, idx) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  key={idx}
                                  style={{
                                    letterSpacing: ".15em",
                                    fontSize: ".5em",
                                    verticalAlign: "middle",
                                    display: "flex"
                                  }}>
                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.startTime}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.endTime}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                        letterSpacing: ".15em"
                                      }}>
                                      {schedule.value.ownerFirstName}{" "}
                                      {schedule.value.ownerLastName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    });
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </div>
          )}
        </div>
      );
    });

    return months;
  }
}

export default connect(
  state => ({
    authUser: state.session.authUser,
    api: state.user.api,
    role: state.user.role,
    rawData: state.data.rawData
  }),
  dispatch => ({
    dispatch,
    reroute: () => dispatch(push("/")),
    getData: (api, role) =>
      dispatch({
        type: DATA_SAGA,
        api,
        role
      }),
    rerouteWithProps: (route, props) => dispatch(push(route, props))
  })
)(Schedule);
