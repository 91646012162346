import { delay, put, takeLatest } from "redux-saga/effects";

//Actions
import * as ACTIONS from "../actions";
import * as UTIL from "./util";

//Functions
import { functions } from "../firebase/firebase";

function* handleFetchRoute(action) {
  const { filter, payload } = action;
  if (filter === "changeSetpoint") {
    const { api, cmd, hashID, value, role } = payload;

    yield put({
      type: ACTIONS.UPDATE_DATA_ASPECT,
      aspect: "changingSetpoint",
      payload: true
    });

    const changeSetpoint = functions.httpsCallable("changeSetpoint");
    const setpointData = { value: value, hashID, cmd, role };

    yield delay(500);

    yield put({
      type: ACTIONS.STOP_POLLING
    });
    yield put({
      type: ACTIONS.START_POLLING,
      api,
      role,
      pause: true
    });

    const data = yield changeSetpoint({ ...setpointData }).then(result => {
      return result;
    });

    if (data && data.message) {
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "changingSetpoint",
        payload: false
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "changingSetpointMessage",
        payload: data.message
      });
    }
  } else if (filter === "changeLights") {
    const { api, cmd, hashID, value, role } = payload;

    yield put({
      type: ACTIONS.UPDATE_DATA_ASPECT,
      aspect: "changingSetpoint",
      payload: true
    });

    const changelight = functions.httpsCallable("changeLight");
    const setpointData = { value: value, hashID, cmd, role };

    yield delay(500);

    yield put({
      type: ACTIONS.START_POLLING,
      api,
      role,
      pause: true
    });

    const data = yield changelight({ ...setpointData }).then(result => {
      return result;
    });

    if (data && data.message) {
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "changingSetpoint",
        payload: false
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "changingSetpointMessage",
        payload: data.message
      });
    }
  } else if (filter === "acknowledge") {
    // ACKNOWLEDGE LIGHT
    const { GUID } = action;
    const acknowledgeAlarm = functions.httpsCallable("acknowledgeAlarm");
    // eslint-disable-next-line
    const acknowledge = yield acknowledgeAlarm({ GUID }).then(result => {
      return result;
    });
  } else {
    const { api, role } = action;
    if (!api) return null;

    try {
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "fetchingData",
        payload: true
      });
      const fetchRoute = functions.httpsCallable("fetchRoute");
      const data = yield fetchRoute({ api, role, route: "" }).then(result => {
        return JSON.parse(result.data);
      });

      const alarms = yield fetchRoute({ api, role, route: "alarms" }).then(
        result => {
          return JSON.parse(result.data);
        }
      );

      const normalizedData = UTIL.dataNormalizer(data);
      const organizedData = UTIL.dataOrganizer(data);

      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "data",
        payload: normalizedData
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "rawData",
        payload: data
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "organizedData",
        payload: { ...organizedData, ...alarms }
      });
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "fetchingData",
        payload: false
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* dataSaga() {
  yield takeLatest(ACTIONS.DATA_SAGA, handleFetchRoute);
}
