import { call, put, takeEvery } from "redux-saga/effects";

//Actions
import * as ACTIONS from "../actions";

//Route
import * as ROUTE from "./../routes";
import { push } from "react-router-redux";

//Firebase Auth
import firebase from "firebase";
import { auth } from "./../firebase";
import { uploadUser } from "./../firebase/db";

function* emailSignUp(options) {
  const { email, password, firstName, lastName } = options;
  let res;
  try {
    res = yield auth
      .doCreateUserWithEmailAndPassword({ email, password })
      .then(user => {
        let currentUser;
        if (user) {
          currentUser = firebase.auth().currentUser;
          currentUser.updateProfile({
            displayName: `${firstName} ${lastName}`
          });
          currentUser.sendEmailVerification();
        }
        return currentUser;
      })
      .catch(error => {
        if ((error.code = "auth/email-already-in-use")) {
          return {
            type: ACTIONS.UPDATE_SESSION_ASPECT,
            aspect: "error",
            payload: error.message
          };
        }
      });
  } catch (err) {
    console.log(err);
  }

  return res;
}

function* facebookSignIn() {
  const fbProvider = new firebase.auth.FacebookAuthProvider();

  const res = yield auth
    .signInWithPopup(fbProvider)
    .then(result => {
      return result;
    })
    .catch(err => {
      console.log(err);
      return err;
    });

  if (res.user) {
    yield put(push(ROUTE.LANDING));
  }

  return res;
}

function* googleSignIn() {
  const googleProvider = new firebase.auth.GoogleAuthProvider();

  const res = yield auth
    .signInWithPopup(googleProvider)
    .then(result => {
      return result;
    })
    .catch(err => {
      console.log(err);
      return err;
    });

  if (res.user) {
    yield put(push(ROUTE.LANDING));
  }

  return res;
}

let result;
function* signUpHandler(route, options) {
  if (route === "email") {
    try {
      result = yield call(emailSignUp, options);
    } catch (err) {
      console.log(err);
    }
  } else if (route === "facebook") {
    result = yield call(facebookSignIn);
  } else if (route === "google") {
    result = yield call(googleSignIn);
  }
  return result;
}

//Saga Handler
function* handleAuth(action) {
  const { dispatch, filter, payload } = action;
  const { email, password, firstName, lastName, api } = payload;
  let error;

  if (filter === ACTIONS.SIGN_IN_EMAIL) {
    //Trigger sign in
    try {
      result = yield call(auth.doSignInWithEmailAndPassword, { email, password });
    } catch (err) {
      error = err;

      if (error.code === "auth/user-not-found") {
        error.message = "No user with this email has been found.";
      }

      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: error.message
      });
    }
  }

  if (filter === ACTIONS.SIGN_UP_EMAIL) {
    //Trigger sign in
    try {
      const authUser = yield call(signUpHandler, "email", {
        email,
        password,
        firstName,
        lastName,
        api
      });

      yield call(uploadUser, authUser.uid, dispatch, {
        email,
        firstName,
        lastName,
        api
      });
    } catch (err) {
      error = err;
      console.log(error);
    }
  }

  if (filter === ACTIONS.SIGN_IN_FACEBOOK) {
    //Trigger sign in
    result = yield call(signUpHandler, "facebook");
  }

  if (filter === ACTIONS.SIGN_IN_GOOGLE) {
    result = yield call(signUpHandler, "google");
  }

  if (result && result.aspect) {
    if (result.aspect === "error") {
      yield put(result);
    }
  }
}

export default function* authSaga() {
  yield takeEvery(ACTIONS.AUTH_SAGA, handleAuth);
}
