import React from "react";

//Routes
import { Route, Switch } from "react-router";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Navigation from "../components/Navigation";

import Dashboard from "../pages/Dashboard";
import Landing from "../pages/Landing";
import Profile from "../pages/Profile";
// import Schedule from "../pages/Schedule";

//SUITES
import MapPage from "../pages/Map";
import SchedulePage from "../pages/Schedule";
import ThermalPage from "../pages/Thermal";
import OccupancyPage from "../pages/Occupancy";
import HistoryPage from "../pages/History";

//DATA
import Temperature from "../pages/Temperature";
import Health from "../pages/Health";
import Lights from "../pages/Lights";
import Calendar from "../pages/Calendar";
import SuiteScreen from "../pages/SuiteScreen";

//Auth
export const DASHBOARD = "/dashboard";
export const LANDING = "/";
export const PROFILE = "/profile";
export const SCHEDULE = "/schedule";

//SUITES
export const MAP = "/map";
export const THERMAL = "/thermal";
export const OCCUPANCY = "/occupancy";
export const SUITE_SCREEN = "/suitescreen";

//DATA
export const TEMPERATURE = "/temperature";
export const HEALTH = "/health";
export const LIGHTS = "/lights";
export const CALENDAR = "/calendar";

export const HISTORY = "/temperature/history/:hashID";

//CodeSplit Components
// const Dashboard = asyncComponent(() => import("../components/pages/Dashboard"));

//Routes Object
const routes = (
  <div>
    <Navigation />
    <Switch>
      <Route exact path={DASHBOARD} component={Dashboard} />
      <Route exact path={LANDING} component={Landing} />
      <Route exact path={PROFILE} component={Profile} />
      {/* <Route exact path={SCHEDULE} component={Schedule} /> */}

      {/* SUITES */}
      <Route exact path={MAP} component={MapPage} />
      <Route exact path={THERMAL} component={ThermalPage} />
      <Route exact path={OCCUPANCY} component={OccupancyPage} />
      <Route exact path={SCHEDULE} component={SchedulePage} />
      <Route exact path={HISTORY} component={HistoryPage} />

      {/* DATA */}
      <Route exact path={TEMPERATURE} component={Temperature} />
      <Route exact path={HEALTH} component={Health} />
      <Route exact path={LIGHTS} component={Lights} />
      <Route exact path={CALENDAR} component={Calendar} />
      <Route exact path={SUITE_SCREEN} component={SuiteScreen} />
    </Switch>
  </div>
);

export default routes;
